import React from "react";

import ReactSvgMap from "../../components/SvgMap/ReactSvgMap";
import { svgPathMap } from "../../components/SvgMap/svgMapConstants";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { SpinnerCircularFixed } from "spinners-react";

function Representatives() {
  const { t: translate } = useTranslation();

  const {
    events: {
      repsentativesData: {
        data: repsentativesData,
        isLoading: isLoadingRepresentatives,
      },
    },
  } = useSelector((state) => ({
    events: state.events,
  }));

  const representativesWithEmployees = repsentativesData.filter(
    (representative) => representative.persons.length > 0
  );

  return (
    <div className="min-h-screen pt-[55px]">
      <div className="pt-[55px] flex flex-col justify-center items-center">
        <h2 className="text-4xl ">{translate("representatives_title")}</h2>
        <p>{translate("representatives_map_helper")}</p>

        {isLoadingRepresentatives && (
          <div className="pb-[300px] pt-[55px] flex items-start justify-center ">
            <SpinnerCircularFixed
              size={100}
              thickness={157}
              speed={300}
              color="#44bb92"
              secondaryColor="rgba(0, 0, 0, 0.44)"
            />
          </div>
        )}
        {!isLoadingRepresentatives && (
          <div
            className="mt-20 w-full flex  justify-center items-center"
            translate="no"
          >
            <ReactSvgMap
              withHiddenMargins={true}
              svgPathData={svgPathMap}
              containerClassName="mb-2"
              modalContentComponent={(selectedPath) => {
                const selectedRepresentative =
                  representativesWithEmployees.find(
                    (rep) => rep.symbol === selectedPath.symbol
                  );

                if (!selectedRepresentative) {
                  return null;
                }
                return (
                  <SelectedModalContent
                    selectedRepresentative={selectedRepresentative}
                  />
                );
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const SelectedModalContent = ({ selectedRepresentative }) => {
  const { t: translate } = useTranslation();
  if (!selectedRepresentative) {
    return null;
  }

  return (
    <div>
      {selectedRepresentative?.persons.map((person, idx) => {
        const { name, department, email, prefix, phone } = person;
        return (
          <div key={`person-${idx}`} className="representatives-info">
            <h5>
              <strong>{name}</strong> - {translate(department)}
            </h5>
            <a href={`mailto:${email}`}>
              <span>
                <i className="fa fa-envelope-o" aria-hidden="true" />
              </span>
              {email}
            </a>
            <a href={`tel:${prefix} ${phone}`}>
              <span>
                <i className="fa fa-phone" aria-hidden="true" />
              </span>
              {phone}
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default Representatives;
