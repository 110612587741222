import React from "react";
import Button from "./Button";
import { toAbsoluteUrl } from "../helpers/assetHelper";

function Department({
  image,
  title,
  description,
  descriptions,
  className,
  buttonText,
  onClick,
}) {
  return (
    <div
      className={`${className} w-[460px] flex flex-col justify-between items-center py-[24px] `}
    >
      <div className="flex flex-col items-center justify-center select-none px-[30px]">
        <img
          src={toAbsoluteUrl(image)}
          alt="pictures"
          width={155}
          className="p-5 pointer-events-none"
        />
        <h2 className="text-white text-xl font-semibold min-h-[56px]">
          {title}
        </h2>
        <p className="text-white text-justify text-[14px] line-clamp-4">
          {description}
        </p>

        {descriptions}
      </div>
      {buttonText && (
        <Button variant="secondary" className="!h-fit mt-5" onClick={onClick}>
          {buttonText}
        </Button>
      )}
    </div>
  );
}

export default Department;
