import React from "react";
import Button from "./Button";

function Content({
  title,
  CustomTitle,
  description,
  descriptions,
  htmlDescriptions,
  link,
  buttonText,
  wrapperClassName,
  contentClassName,
  buttonVariant,
  contentType,
  descriptionColor,
  descriptionClass,
  onClick,
}) {
  return (
    <div className={`${wrapperClassName}`}>
      <div className={`${contentClassName} flex flex-col select-none`}>
        {!CustomTitle && (
          <h2
            className={`text-xl lg:text-2xl xl:text-3xl  ${
              contentType === "secondary"
                ? "text-white drop-shadow"
                : "text-primary-color"
            }  font-bold mb-6`}
          >
            {title}
          </h2>
        )}
        {!!CustomTitle && <CustomTitle />}
        {description && (
          <p
            className={`${
              contentType === "secondary"
                ? "text-white drop-shadow"
                : `${descriptionColor} md:text-black`
            }  text-sm xl:text-base font-medium ${descriptionClass} mb-6`}
          >
            {description}
          </p>
        )}

        {descriptions}
        <div
          className={`${contentType === "secondary" ? "drop-shadow" : ""}`}
          dangerouslySetInnerHTML={{ __html: htmlDescriptions }}
        />
        {link && (
          <a href={link} className="text-sky-400 font-bold	">
            Sursa{" "}
          </a>
        )}

        {buttonText && (
          <Button
            onClick={onClick}
            variant={`${
              contentType === "secondary" ? "secondary" : buttonVariant
            }`}
            className="!h-fit !w-fit  mt-6"
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
}

export default Content;
