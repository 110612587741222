import React, { useEffect, useState } from "react";
import SiteLang from "./SiteLang";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import KTSVG from "../utils/KTSVG";

const externalNavbarPages = [
  {
    name: "navbar_link_home",
    to: "/",
  },
  {
    name: "navbar_link_3",
    to: "/contact",
  },
  {
    name: "navbar_career_link",
    to: "/career",
  },
  {
    name: "navbar_events_link",
    to: "/events",
  },
  {
    name: "navbar_representatives_link",
    to: "/representatives",
  },
];

function ExternalNavbar({ ordersButtonText }) {
  const { t: translate } = useTranslation();
  let location = useLocation();
  const currentUrl = location.pathname;

  const [showNav, setShowNav] = useState(true);

  const [activeLink, setActiveLink] = useState("/");

  useEffect(() => {
    const paramsStartingIndex = currentUrl.indexOf("?");

    const getSlicedString = () => {
      if (paramsStartingIndex > 0) {
        return currentUrl.substring(0, paramsStartingIndex);
      }
      return currentUrl;
    };

    setActiveLink(`${getSlicedString()}`);
  }, [currentUrl]);

  const handleShowNav = () => {
    setShowNav(!showNav);
  };

  return (
    <nav className="flex flex-row justify-between items-center font-semibold top-0 z-50 h-14 fixed w-full bg-white border-b border-gray-100 pl-3 xl:px-20">
      <Link to="/">
        <KTSVG className="w-[110px]" path="/assets/logo.svg" />
      </Link>

      <ul className="hidden xl:flex md:flex-row items-center ">
        {externalNavbarPages.map((externalNavbarPage) => {
          return (
            <Link
              key={externalNavbarPage.to}
              to={externalNavbarPage.to}
              className={`${
                externalNavbarPage.to === activeLink
                  ? "navbar-active-page-btn"
                  : "navbar-page-btn"
              }`}
            >
              {translate(externalNavbarPage.name)}
            </Link>
          );
        })}
      </ul>

      <div className="flex justify-center items-center">
        {ordersButtonText && (
          <a
            target="_blank"
            rel="noreferrer"
            href="http://comenzi.2afarm.ro/"
            className="text-secondary-color border-x border-secondary-color px-3 py-3.5"
          >
            {translate(ordersButtonText)}
          </a>
        )}
        <SiteLang
          wrapperClassName="px-2 py-2 h-auto hidden xl:flex "
          dropdownClassName="flex flex-col mr-[80px] bg-primary-color bg-opacity-80 px-2 py-2  absolute right-0 top-[54px] cursor-pointer ease-out duration-500"
        />

        <div>
          <div onClick={handleShowNav} className="xl:hidden">
            {showNav ? (
              <AiOutlineMenu
                size={45}
                className="text-primary-color cursor-pointer px-3"
              />
            ) : (
              <AiOutlineClose
                size={45}
                className="text-primary-color cursor-pointer px-3"
              />
            )}
          </div>

          <div
            className={
              !showNav
                ? "bg-primary-color bg-opacity-90 backdrop-blur-sm ease-out duration-500 fixed right-0 top-[54px] h-full w-full  "
                : "ease-out duration-500 fixed left-[-100%]"
            }
          >
            <SiteLang
              onClickLanguage={handleShowNav}
              wrapperClassName="flex justify-end p-5 border-b border-gray-300"
              dropdownClassName="flex flex-row-reverse p-5 absolute right-[50px] top-0"
              dropdownImageClassName="ml-2"
            />

            <ul className="flex flex-col ">
              {externalNavbarPages.map((externalNavbarPage) => {
                return (
                  <Link
                    onClick={handleShowNav}
                    key={externalNavbarPage.to}
                    to={externalNavbarPage.to}
                    className={`${
                      externalNavbarPage.to === activeLink
                        ? "navbar-responsive-active-page-btn"
                        : "navbar-responsive-page-btn"
                    }`}
                  >
                    {translate(externalNavbarPage.name)}
                  </Link>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default ExternalNavbar;
